import Endpoint from "./Endpoint";
import { Invitations, Authorization, User, app_metadata, user_metadata } from "interfaces/Auth0";
import { Organization } from "interfaces/Organization";
export default class Auth0 {
  readonly client_id: string | undefined;
  readonly client_secret: string | undefined;
  readonly url: string | undefined;
  readonly endpoint: Endpoint;
  readonly audience: string | undefined;
  readonly auth0: any;

  constructor(
    ...args:
      | []
      | [
          client_id: string | null | undefined,
          client_secret: string | null | undefined,
          url: string | null | undefined,
          audience: string | null | undefined
        ]
  ) {
    this.client_id = args[0] || process.env.REACT_APP_AUTH0_CLIENT_M2MID;
    this.client_secret = args[1] || process.env.REACT_APP_AUTH0_CLIENT_M2MSK;
    this.url = args[2] || process.env.REACT_APP_AUTH0_CLIENT_M2M_DOMAIN;
    this.audience =
      args[3] ||
      `https://${process.env.REACT_APP_AUTH0_CLIENT_M2M_DOMAIN}/api/v2/`;
    this.endpoint = new Endpoint();
  }
  getInvitationsToOrganization = async (org_id: string) => {
    const Authorization: Authorization = await this.getToken();
    const _inviteResp = await this.endpoint.getOptions(
      `https://${this.url}/api/v2/organizations/${org_id}/invitations?include_totals=true&include_fields=true&per_page=100`,
      {
        headers: {
          Authorization: `${Authorization.token_type} ${Authorization.access_token}`,
        },
      }
    );
    const invitations: Invitations = _inviteResp.data;
    return invitations;
  };
  getInvitationToOrganization = async (org_id: string, invitation: string) => {
    const invitations: Invitations = await this.getInvitationsToOrganization(
      org_id
    );
    const _ = require("lodash");
    const _invitation = _.find(invitations.invitations, [
      "ticket_id",
      invitation,
    ]);
    return _invitation;
  };
  createUser = async (_user: User) => {
    const Authorization: Authorization = await this.getToken();
    const userResp = await this.endpoint.putOptions(
      `https://${this.url}/api/v2/users`,
      _user,
      {
        headers: {
          Authorization: `${Authorization.token_type} ${Authorization.access_token}`,
        },
      }
    );
    const user: User = userResp.data;
    return user;
  };
  viewDashboard = async (claims: any, user: User | undefined) => {
    try {
      const hasOrg = await this.hasOrg(user);
      if (hasOrg) {
        const did_token = claims?.__raw;
        if(typeof did_token === "undefined"){
          throw new Error('invalid user');
        }
        const provider = await this.Provider(user);
        window.location.replace(this.dashboardLoginViaSSOUrl(did_token, provider));
      }else{
        window.location.replace(process.env.REACT_APP_AUTH0_SOCIAL_LOGIN_REDIRECT_URL || "https://get.backpac.xyz/dashboard");
      }
    } catch (error: any) {
      console.error(error);
    }
  };
  createAccountForSocial = async (did_token: string, account: any) => {
    const create = await this.endpoint.postApiClientWithAdditionalParams(
      "organization/user/social",
      account,
      {
        headers: { "x-backpac-token": did_token, platform: "desktop" },
      }
    );
    return create.data;
  };
  createUserAndAccount = async (account: any) => {
    const create = await this.endpoint.postApiClientWithAdditionalParams(
      "organization/user",
      account,
      {
        headers: { platform: "desktop" },
      }
    );
    return create.data;
  };
  createOrganization = async (ORG: Organization) => {
    const Authorization: Authorization = await this.getToken();
    const authResp = await this.endpoint.putOptions(
      `https://${this.url}/api/v2/organizations`,
      {
        name: ORG.ORG_NAME,
        display_name: ORG.ORG_DISPLAY_NAME,
        branding: {
          logo_url: ORG.orgLogo,
          colors: {
            primary: ORG.orgPrimaryColor,
            page_background: ORG.orgPageBackground,
          },
        },
        metadata: ORG.metadata,
        enabled_connections: [
          {
            connection_id: ORG.connection_id,
          },
        ],
      },
      {
        headers: {
          "content-type": "application/json",
          "cache-control": "no-cache",
          Authorization: `${Authorization.token_type} ${Authorization.access_token}`,
        },
      }
    );

    return authResp.data;
  };
  getToken = async () => {
    const authResp = await this.endpoint.postExternal(
      `https://${this.url}/oauth/token`,
      {
        client_id: this.client_id,
        client_secret: this.client_secret,
        audience: this.audience,
        grant_type: "client_credentials",
      }
    );
    const Authorization: Authorization = authResp.data;
    return Authorization;
  };
  getSocialAuthorizeUrl = async (state: string) => {
    return `https://${process.env.REACT_APP_AUTH0_DOMAIN}/authorize?response_type=token&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&connection=google-oauth2&redirect_uri=${window.location.origin}&state=${state}`;
  };
  dashboardLoginViaSSOUrl = (jwt: string, provider: string) => {
    return `${process.env.REACT_APP_DASHBOARD_SSO_URL}?jwt=${jwt}&provider=${provider || "backpac"}`;
  };
  hasOrg = (user: User | undefined) => {
    if (typeof user !== "undefined") {
      const metadata: app_metadata | undefined =
        user["https://auth.backpac.xyz/app_metadata"];

      if (typeof metadata === "undefined") return false;

      return typeof metadata["account-id"] === "undefined" ? false : true;
    }

    return false;
  };
  Provider = (user: User | undefined): string => {
    if (typeof user !== "undefined") {
      const metadata: user_metadata | undefined =
        user["https://auth.backpac.xyz/user_metadata"];

      if (typeof metadata === "undefined") return "backpac";

      return metadata["provider"];
    }

    return "backpac";
  };
  isWaitlisted = (user: User | undefined): boolean => {
    if (typeof user !== "undefined") {
      const user_metadata: user_metadata | undefined =
        user["https://auth.backpac.xyz/user_metadata"];
      const app_metadata: app_metadata | undefined =
        user["https://auth.backpac.xyz/app_metadata"];

      if (typeof user_metadata === "undefined") return false;

      if (typeof app_metadata === "undefined") return false;

      if (typeof app_metadata["wait-listed"] === "undefined") {
        return typeof user_metadata["wait-list"] === "undefined"
          ? false
          : user_metadata["wait-list"];
      } else {
        return app_metadata["wait-listed"];
      }
    }

    return false;
  };
  loginViaSocialSSO = async (jwt: string, org_name: string) => {
    const resp = await this.endpoint.get(
      `backpac/sso/social/${jwt}/${org_name}`
    );
    const data: any = resp.data;
    return data;
  };
  loginViaSSO = async (jwt: string) => {
    const resp = await this.endpoint.get(`backpac/sso/${jwt}`);
    const data: any = resp.data;
    return data;
  };
}