import React, { useState, useEffect } from "react";
import Logo from "shared/Logo/Logo";
//import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
//import { builder } from "@builder.io/sdk";
export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

// const widgetMenus: WidgetFooterMenu[] = [
//   // {
//   //   id: "5",
//   //   title: "Getting started",
//   //   menus: [
//   //     { href: "#", label: "Installation" },
//   //     { href: "#", label: "Release Notes" },
//   //     { href: "#", label: "Upgrade Guide" },
//   //     { href: "#", label: "Browser Support" },
//   //     { href: "#", label: "Editor Support" },
//   //     { href: "#", label: "Dark Mode" },
//   //   ],
//   // },
//   {
//     id: "1",
//     title: "Explore",
//     menus: [
//       // { href: "#", label: "Design features" },
//       // { href: "#", label: "Prototyping" },
//       // { href: "#", label: "Design systems" },
//       // { href: "#", label: "Pricing" },
//       { href: "/product", label: "Product" },
//       { href: "/brand", label: "Brands" },
//       { href: "/person", label: "Consumers" },
//     ],
//   },
//   {
//     id: "2",
//     title: "Resources",
//     menus: [
//       // { href: "#", label: "Best practices" },
//       { href: "/blog", label: "Blog" },
//       { href: "/contact", label: "Contact Us" },
//       //{ href: "/developers", label: "Developers" },
//       //{ href: "https://helpapi.backpac.xyz", label: "API Reference", targetBlank: true },
//       // { href: "#", label: "Learn design" },
//       //{ href: "#", label: "What's new" },
//       // { href: "#", label: "Releases" },
//     ],
//   },
//   // {
//   //   id: "4",
//   //   title: "Community",
//   //   menus: [
//   //     // { href: "#", label: "Discussion Forums" },
//   //     // { href: "#", label: "Code of Conduct" },
//   //     // { href: "#", label: "Community Resources" },
//   //     // { href: "#", label: "Contributing" },
//   //     { href: "#", label: "Concurrent Mode" },

//   //   ],
//   // },
// ];

const Footer: React.FC = () => {
  const [widgetMenus, setWidgetMenus] = useState<WidgetFooterMenu[]>([]);

  const menu = [
    {
      menus: [
        {
          label: "Pricing",
          href: "/pricing",
        },
        {
          label: "Contact Us",
          href: "/contact",
        },
        {
          label: "Terms of Service",
          href: "/terms",
        },
        {
          label: "Privacy Policy",
          href: "/privacy",
        },
        {
          label: "Medium",
          href: "https://medium.com/@backpacinc",
          id: "2",
          targetBlank: true,
          targetExternal: true,
        },
        {
          label: "LinkedIn",
          href: "https://www.linkedin.com/company/backpac-inc",
          targetBlank: true,
          targetExternal: true,
        },
        {
          label: "Twitter",
          href: "https://twitter.com/backpacxyz/",
          targetBlank: true,
          targetExternal: true,
        },
      ],
      id: "1",
      title: "Blockchain RPC",
    },
    {
      menus: [
        {
          label: "Jupiter V6 Swap API",
          href: "/add-on/jupiter",
        },
        {
          label: "Jito API Load Balancer",
          href: "/jito-load-balancer",
        },
        {
          label: "Jito Bundles & Transactions",
          href: "https://help.backpac.xyz/jito/overview.html",
          targetBlank: true,
          targetExternal: true,
        },
        {
          label: "Parallel RPC Request Processing",
          href: "https://help.backpac.xyz/rpc-load-balancer/features/parallel-rpc-request-processing.html",
          targetBlank: true,
          targetExternal: true,
        },
        {
          label: "Documentation",
          href: "https://help.backpac.xyz/",
          targetBlank: true,
          targetExternal: true,
        },
        {
          label: "Solana Ore Mining",
          href: "/blockchain/solana/ore-mining",
        },
        {
          label: "Blog",
          href: "/blog",
        },
      ],
      id: "2",
      title: "Resources",
    },
    {
      menus: [
        {
          label: "Ethereum",
          href: "/blockchain/ethereum",
          id: "1",
        },
        {
          label: "Polygon",
          href: "/blockchain/polygon",
          id: "2",
        },
        {
          label: "Solana",
          href: "/blockchain/solana",
          id: "3",
        },
        {
          label: "Binance Smart Chain",
          href: "/blockchain/binance-smart-chain",
          id: "4",
        },
        // {
        //   label: "Avalanche",
        //   href: "/blockchain/avalanche",
        //   id: "5",
        // },
        {
          label: "Arbitrum",
          href: "/blockchain/arbitrum",
          id: "6",
        },
        {
          label: "Optimism",
          href: "/blockchain/optimism",
          id: "7",
        },
        {
          label: "Base",
          href: "/blockchain/base",
          id: "8",
        },
        // {
        //   label: "Gnosis",
        //   href: "/blockchain/gnosis",
        //   id: "9",
        // },
      ],
      id: "3",
      title: "Blockchains",
    },
    // {
    //   menus: [
    //     {
    //       label: "Arweave",
    //       href: "/permaweb/arweave",
    //     },
    //   ],
    //   id: "4",
    //   title: "PermaWeb",
    // },
  ];

  useEffect(() => {
    setWidgetMenus(menu);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
                target={item.targetBlank ? "_blank" : "_self"}
                rel="noopener noreferrer"
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  if(document.location.pathname === "/health"){
    return <></>
  }

  return (
    <div className="nc-Footer relative py-20 lg:pt-32 lg:pb-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          {/* <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
          </div> */}
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;
