import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // useNavigate instead of useHistory
import { useAuth0 } from '@auth0/auth0-react';

const AwsLoginRedirect: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate(); // useNavigate hook

  

  useEffect(() => {
    // Trigger the Auth0 login redirect when this component mounts
    const redirectToLogin = async () => {
      await loginWithRedirect();
    };

    redirectToLogin();
  }, [loginWithRedirect, navigate]);

  return (
    <div>
      <h2>Redirecting to login...</h2>
      {/* Optionally, display a loading spinner or message */}
    </div>
  );
};

export default AwsLoginRedirect;
