import { useAuth0 } from '@auth0/auth0-react';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DASHBOARDICON from "images/icons/dashboard.png"
import ARWEAVE from "images/providers/arweave.svg";
export default function Hero() {
  const {isAuthenticated, loginWithRedirect } = useAuth0();
  return (
    <div className="bg-white dark:bg-neutral-900">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#77B3DF] to-[#3BA5F3] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <img
              src={ARWEAVE}
              alt={"Backpac's Arweave Gateway"}
              className="rounded w-24 h-24 align-left"
            />
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-6xl">
              Your Gateway to Eternal Data on Arweave's Permaweb
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-neutral-400">
              Effortlessly access and query information on the Permaweb using
              Backpac's Arweave Gateway — a key to Arweave's globally
              distributed permanent hard drive. Enjoy smooth retrieval and
              caching, ensuring a lasting digital presence
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <ButtonPrimary
                onClick={() => {
                  sessionStorage.setItem("plan", "arweave");
                  !isAuthenticated
                    ? loginWithRedirect()
                    : (document.location.href = "/dashboard");
                }}
                style={{
                  backgroundColor: "#1D476D",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className=""
              >
                <span role="img" aria-label="quicknode">
                  <img
                    style={{ marginRight: "8px" }}
                    src={!isAuthenticated ? ARWEAVE : DASHBOARDICON}
                    alt={
                      !isAuthenticated
                        ? "Backpac Sign Up"
                        : "Go To Backpac Dashboard"
                    }
                    className="object-cover object-center rounded w-6 h-6"
                  />
                </span>
                {isAuthenticated ? "Dashboard" : "Sign Up for Arweave Gateway"}
              </ButtonPrimary>
              {/* <a
                href="https://help.backpac.xyz"
                target="_new"
                className="text-sm font-semibold leading-6 text-gray-900 dark:text-white"
              >
                Read the docs <span aria-hidden="true">→</span>
              </a> */}
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#77B3DF] to-[#3BA5F3] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </div>
  );
}