import { Link } from "react-router-dom";
import ETH from "../../../images/logo/ethereum-2.png";
import BASE from "../../../images/logo/base.png";
import MATIC from "../../../images/logo/polygon.png";
import SOL from "../../../images/logo/solana.png";
import JITO from "../../../images/logo/jito.png";
import JUPITER from "../../../images/logo/jupiter.png";
// import GNOSIS from "../../../images/logo/gnosis.png"
import BSC from "../../../images/logo/bsc.png";
import OPT from "../../../images/logo/optimism.png";
// import AVAX from "images/logo/avalanche.png";
import ARB from "../../../images/logo/arbitrum.png";
import TRON from "../../../images/logo/tron.png";
import ETC from "../../../images/logo/etc.png"
const Networks = () => {

  const stats = [
    {
      data: ARB,
      title: "ARB",
      alt: 'Arbitrum Smart Chain (Arbitrum)'
    },
    {
      data: BASE,
      title: "Base",
      alt: 'Coinbase BASE Blockchain (Base)'
    },
    {
      data: BSC,
      title: "BSC",
      alt: 'Binance Smart Chain (Binance)'
    },
    {
      data: ETH,
      title: "Ethereum",
      alt: 'Ethereum Blockchain (Ethereum)',
      link: '/blockchain/ethereum'
    }, {
      data: ETC,
      title: "Ethereum Classic",
      alt: 'Ethereum Classic Blockchain (Ethereum Classic)'
    },
    {
      data: JITO,
      title: "Jito",
      alt: 'Jito MEV (Solana)',
      link: '/jito-load-balancer'
    },
    {
      data: JUPITER,
      title: "Jupiter",
      alt: 'Jupiter V6 Swaps (Solana)',
      link: '/add-on/jupiter'
    },
    {
      data: MATIC,
      title: "Polygon",
      alt: 'Polygon MATIC Blockchain (Polygon)'
    },
    {
      data: SOL,
      title: "Solana",
      alt: 'Solana Blockchain (Solana)',
      link: '/blockchain/solana'
    },
    {
      data: TRON,
      title: "Tron",
      alt: 'Tron Blockchain (Tron)'
    },
    {
        data: OPT,
        title: "Optimism",
        alt: 'Optimism Blockchain (Optimism)'
    },
    // {
    //     data: AVAX,
    //     title: "Avalanche",
    //     alt: 'Avalanche Blockchain (Avalanche)'
    // },        
  ]

    return (
      <section className="py-10">
        <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h3 className="text-3xl font-semibold text-neutral-900 dark:text-neutral-100 text-3xl xl:text-4xl">
              Multi-Network Support
            </h3>
            <p className="mt-3 text-neutral-700 dark:text-neutral-300">
              Access Mainnet, Testnet, and Devnet for Solana, Binance Smart
              Chain, Coinbase BASE, Ethereum, Ethereum Classic, Optimism,
              Polygon, Tron and other EVM-based blockchains, including seamless
              integration with Jito and Jupiter—Backpac is your unified solution
              for blockchain data access.
            </p>
          </div>
          <div className="mt-12">
            <ul className="flex flex-col items-center justify-center gap-y-10 sm:flex-row sm:flex-wrap lg:divide-x">
              {stats.map((item, idx) => (
                <li
                  key={idx}
                  className="flex flex-col items-center text-center px-12 md:px-16"
                >
                  {item.link ? (
                    <Link to={item.link} aria-label={item.alt}>
                      <img
                        src={item.data}
                        alt={item.alt}
                        title={item.alt}
                        className="object-cover object-center rounded w-10 h-10"
                      />
                      <p className="mt-3 font-small text-neutral-700 dark:text-neutral-300">
                        {item.title}
                      </p>
                    </Link>
                  ) : (
                    <>
                      <img
                        src={item.data}
                        alt={item.alt}
                        title={item.alt}
                        className="object-cover object-center rounded w-10 h-10"
                      />
                      <p
                        title={item.alt}
                        className="mt-3 font-small text-neutral-700 dark:text-neutral-300"
                      >
                        {item.title}
                      </p>
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    );
}

export default Networks;