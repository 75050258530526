import { useEffect, useState } from "react"
//import { builder } from '@builder.io/sdk'
import NavigationItem from "./NavigationItem";
import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

function Navigation() {

  const [nav] = useState<NavItemType[]>([
    // {
    //   id: "1",
    //   href: "",
    //   name: "Menu",
    //   type: "dropdown",
    //   children: [
    //     {
    //       name: "Home",
    //       href: "/",
    //       id: "1.1",
    //     },
    //     {
    //       name: "Pricing",
    //       href: "/pricing",
    //       id: "1.2",
    //     },
    //     {
    //       name: "Contact Us",
    //       href: "/contact",
    //       id: "1.3",
    //     },
    //     {
    //       name: "Documentation",
    //       href: "https://help.backpac.xyz/",
    //       id: "1.4",
    //       targetBlank: true,
    //       targetExternal: true
    //     }
    //   ],
    // },
    {
      name: "Home",
      href: "/",
      id: ncNanoId(),
    },
    {
      name: "AWS Marketplace",
      href: "https://aws.amazon.com/marketplace/seller-profile?id=seller-rcnvy3yx6utag",
      id: ncNanoId(),
      targetBlank: true,
      targetExternal: true
    },
    // {
    //   name: "Jito Load Balancer",
    //   href: "/jito-load-balancer",
    //   id: ncNanoId(),
    // },
    // {
    //   name: "Jupiter V6 Swap API",
    //   href: "/add-on/jupiter",
    //   id: ncNanoId(),
    // },
    {
      name: "Pricing",
      href: "/pricing",
      id: ncNanoId(),
    },
    // {
    //   name: "Ethereum",
    //   href: "/blockchain/ethereum",
    //   id: ncNanoId(),
    // },
    // {
    //   name: "Solana",
    //   href: "/blockchain/solana",
    //   id: ncNanoId(),
    // },
    // {
    //   name: "Solana ORE",
    //   href: "blockchain/solana/ore-mining",
    //   id: ncNanoId(),
    // },
    {
      name: "Docs",
      href: "https://help.backpac.xyz/",
      id: ncNanoId(),
      targetBlank: true,
      targetExternal: true,
    },
    // {
    //   name: "Contact Us",
    //   href: "/contact",
    //   id: ncNanoId(),
    // },
  ]);

  useEffect(() => {
    // async function fetchContent() {
    //   let data = await builder.get('main-menu').promise().then(({ data }) => {
    //     console.log(data);
    //     return data
    //   })
    //   nav[0].children = data.childMenus;
    //   setNav(nav);
    // }
    // fetchContent();
  });


  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {nav.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}
export default Navigation;