//import Flow from "./flow/Flow";
import { useEffect  } from "react";
//import { PopupButton } from "@typeform/embed-react";
import { Helmet } from "react-helmet";
import Plus from "./sections/Plus";
import Networks from "./sections/Networks";
// import Features from "./sections/Features";
// import Connect from "./sections/Connect";
//import Enterprise from "./sections/Enterprise";
//import DAO from "images/dao-2.png";
//import SIGNUPICON from "images/icons/sign-up.png";
//import DASHBOARDICON from "images/icons/dashboard.png"
// import Providers from "./sections/Providers";
// import FeaturesAcrossProviders from "./sections/FeaturesAcrossProviders";
// import MultiProvider from "./sections/MultiProvider";
import { useAuth0 } from '@auth0/auth0-react';
import ButtonSecondaryTrans from "shared/Button/ButtonSecondaryTrans";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BookADemo from "containers/PageSubscription/BookADemo";
const Home = () => {
  const {isAuthenticated, loginWithRedirect } = useAuth0();
  useEffect(() => {
    //const plan = sessionStorage.getItem('plan');
    // if(plan !== null){
    //   console.log(plan)
    // }

  }, []);
  
 const jsonLdSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  "url": "https://www.backpac.xyz/",
  "name": "Backpac",
  "description": "No Hardware, No Hassle. Just Reliable RPC Performance. High performance, reliability, and cost efficiency—no hardware or maintenance required",
  "inLanguage": "en",
  "publisher": {
    "@type": "Organization",
    "name": "Backpac",
    "url": "https://www.backpac.xyz",
    "logo": "https://cdn.backpac.xyz/crop.png",
    "sameAs": [
      "https://www.linkedin.com/company/backpacxyz/",
      "https://twitter.com/BackpacXYZ"
    ]
  },
  "mainEntity": {
    "@type": "Organization",
    "name": "Backpac",
    "description": "No Hardware, No Hassle. Just Reliable RPC Performance. High performance, reliability, and cost efficiency—no hardware or maintenance required.",
    "url": "https://www.backpac.xyz",
    "logo": "https://cdn.backpac.xyz/crop.png",
    "contactPoint": [
      {
        "@type": "ContactPoint",
        "contactType": "Customer Support",
        "email": "support@backpac.xyz",
        "areaServed": "Global",
        "availableLanguage": ["English"]
      }
    ]
  }
};

  return (
    <>
      <Helmet>
        <title>RPC Load Balancer and Cache Service</title>
        <meta
          name="description"
          content={
            "A Layer 7 RPC load balancer designed for fault-tolerant, high-performance blockchain access. Prevent downtime, optimize traffic, and accelerate transactions with smart routing, failover protection, and cost-efficient caching—all without extra infrastructure."
          }
        />
        <meta
          name="keywords"
          content={
            "Proxy, RPC Proxy, EVM Proxy, RPC load balancer, Solana, Ethereum, fault-tolerant, high-performance, blockchain infrastructure, cost efficiency, simplify RPC, no hardware required, reliable blockchain connection"
          }
        />
        <meta
          name="twitter:title"
          content="RPC Load Balancer and Cache Service"
        />
        <meta
          name="twitter:description"
          content="Seamlessly connect Solana and Ethereum with a fault-tolerant RPC load balancer, offering high performance, reliability, and cost efficiency—no hardware or maintenance required."
        />
        <meta
          name="twitter:image:alt"
          content="RPC Load Balancer and Cache Service"
        />
        <meta
          property="og:image:alt"
          content="RPC Load Balancer and Cache Service"
        />
        <link rel="canonical" href="https://backpac.xyz" />
        <script type="application/ld+json">
          {JSON.stringify(jsonLdSchema)}
        </script>
      </Helmet>
      {/* <Flow />  */}
      <section
        className="text-black body-font"
        style={{ marginBottom: "10px" }}
      >
        <div className="p-3 relative max-w-2xl mx-auto sm:text-center sm:space-x-5 mt-6 sm:mt-12">
          <div className="relative z-10">
            {/* <h1 className="title-font tracking-wide text-4xl xl:text-6xl mb-4 font-bold text-neutral-900 dark:text-neutral-100 ">
              <span style={{ color: "#3BA5F3" }}>Load Balance</span>
              <br />
              RPC Request
              Blockchain RPC Request
            </h1> */}
            <h1 className="title-font tracking-wide text-4xl xl:text-6xl mb-4 font-bold text-neutral-900 dark:text-neutral-100 ">
              <span style={{ color: "#1D476D" }}>Next-Gen&nbsp;</span><span style={{ color: "#3BA5F3" }}>RPC</span><br />Load Balancer
            </h1>
            <p className="mt-3 text-neutral-700 dark:text-neutral-300">
              {/* <b>
                <span style={{ color: "#1D476D" }}>Back</span>
                <span style={{ color: "#3BA5F3" }}>pac&trade;</span>
                <i>{" "}</i>
              </b> */}
              A Layer 7 RPC load balancer designed for fault-tolerant,
              high-performance blockchain access. Prevent downtime, optimize
              traffic, and accelerate transactions with smart routing, failover
              protection, and cost-efficient caching—all without extra
              infrastructure.
              {/* Connect Solana, Ethereum, and other EVM blockchains with an RPC
              load balancer offering a fault-tolerant, high-performance endpoint
              for enhanced reliability and cost-efficient caching. */}
              {/* is an intelligent RPC load balancer for scaling blockchain data
              access to blockchain networks. Distribute RPC request across
              multiple node providers of your choice, providing you with
              redundancy, increased rate limits and caching. */}
              {/* reliability, scalability and cost savings. */}
            </p>
            <p
              className="mt-3 text-neutral-700 dark:text-neutral-300"
              style={{ fontSize: "1.25rem", fontWeight: "normal" }}
            >
              {/* <b>No Hardware, No Hassle: Simplify Your RPC Load Balancing</b> */}
              <b>No Hardware, No Hassle. Just Reliable RPC Performance.</b>
            </p>
            {/* <p
              className="mt-3 text-neutral-700 dark:text-neutral-300"
              style={{ fontSize: "1.25rem", fontWeight: "normal" }}
            >
              Start with our{" "}
              <span style={{ fontWeight: "bold", color: "#007BFF" }}>
                Velocity Plan
              </span>{" "}
              at{" "}
              <span style={{ fontWeight: "bold", color: "#007BFF" }}>
                $479.00/month
              </span>
              , or upgrade to{" "}
              <span style={{ fontWeight: "bold", color: "#007BFF" }}>
                High Throughput
              </span>{" "}
              for{" "}
              <span style={{ fontWeight: "bold", color: "#007BFF" }}>
                $1849.00/month
              </span>
              .
            </p> */}
          </div>
          <div className="relative z-10 sm:space-x-5 mt-6 sm:mt-12">
            <ButtonPrimary
              onClick={() => {
                //const url = "https://help.backpac.xyz/";
                //window.open(url, "_blank");
                !isAuthenticated
                  ? loginWithRedirect()
                  : (document.location.href = "/dashboard");
              }}
              style={{
                backgroundColor: "#3BA5F3",
                justifyContent: "center",
                alignItems: "center",
              }}
              className=""
            >
              {/* <span role="img" aria-label="quicknode">
                  <img
                    style={{ marginRight: "5px" }}
                    src={!isAuthenticated ? SIGNUPICON : DASHBOARDICON}
                    alt={
                      !isAuthenticated
                        ? "Backpac Sign Up"
                        : "Go To Backpac Dashboard"
                    }
                    className="object-cover object-center rounded w-6 h-6"
                  />
                </span> */}
              {isAuthenticated ? "Dashboard" : "Get Started"}
            </ButtonPrimary>
            <ButtonSecondaryTrans
              onClick={() => {
                document.location.href = "/pricing";
                // window.open(
                //   "https://meetings.hubspot.com/allyn-alford",
                //   "_blank"
                // );
              }}
              className=""
            >
              Explore Plans <span aria-hidden="true"> →</span>
            </ButtonSecondaryTrans>
          </div>
          <div
            className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]"
            style={{
              background:
                "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)",
            }}
          ></div>
        </div>
      </section>

      {/* <Features /> */}
      {/* <MultiProvider /> */}
      {/* <Providers /> */}
      {/* <FeaturesAcrossProviders /> */}

      <Plus />
      <Networks />
      <BookADemo />

      {/* <Enterprise /> */}

      {/* <Customers /> */}
      {/* <Connect /> */}
    </>
  );
};
export default Home;
