import { useAuth0 } from '@auth0/auth0-react';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SIGNUPICON from "images/icons/sign-up.png";
import DASHBOARDICON from "images/icons/dashboard.png"
import SOL from "images/logo/solana.png";
export default function Hero() {
  const {isAuthenticated, loginWithRedirect } = useAuth0();
  return (
    <div className="bg-white dark:bg-neutral-900">
      <div className="relative isolate">
        <div
          className="absolute inset-x-0 -z-10 transform-gpu overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#77B3DF] to-[#3BA5F3] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-2 sm:py-28 lg:py-50">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <img
              src={SOL}
              alt={"Leverage Multiple Solana RPC Providers to Mine Solana ORE"}
              className="rounded w-24 h-24 align-left"
            />
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-6xl">
              Leverage Multiple Solana RPC Providers to Mine Solana ORE
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-neutral-400">
              Maximize Your Solana ORE Mining Potential with Backpac's Dynamic
              RPC Load Balancing! Harness the Power of Multiple Solana RPC
              Providers for to land your transactions.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <ButtonPrimary
                onClick={() => {
                  !isAuthenticated
                    ? loginWithRedirect()
                    : (document.location.href = "/dashboard");
                }}
                style={{
                  backgroundColor: "#3BA5F3",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className=""
              >
                <span role="img" aria-label="Backpac">
                  <img
                    style={{ marginRight: "5px" }}
                    src={!isAuthenticated ? SIGNUPICON : DASHBOARDICON}
                    alt={
                      !isAuthenticated
                        ? "Get Started"
                        : "Go To Backpac Dashboard"
                    }
                    className="object-cover object-center rounded w-6 h-6"
                  />
                </span>
                {isAuthenticated ? "Dashboard" : "Get Started"}
              </ButtonPrimary>
              <a
                href="https://help.backpac.xyz/solana-ore-mining/overview.html"
                target="_new"
                className="text-sm font-semibold leading-6 text-gray-900 dark:text-white"
              >
                Read the docs <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
